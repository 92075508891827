<style lang="scss" scoped>
/deep/ .category-edit {
    max-width: 520px;
}
</style>

<template>
    <div class="page-merchant-categorys">
        <div class="toolbars mrgb5">
            <div class="buttons mrgb5">
                <div class="fl">
                    <el-button type="primary" size="medium" icon="el-icon-plus" @click="_edit()">添加类别</el-button>
                </div>
                <div class="fr">
                    <el-input class="c-el-input mrgr5 mrgb5" placeholder="请输入关键字搜索" clearable v-model="filter.keywords" size="medium"></el-input>
                    <el-button type="primary" @click="_search()" size="medium">查询</el-button>
                </div>
            </div>
        </div>
        <div>
            <el-table :data="tableData" border fit highlight-current-row row-key="id">
                <el-table-column prop="id" label="ID"></el-table-column>
                <el-table-column prop="name" label="姓名"></el-table-column>
                <el-table-column prop="sort" label="排序"></el-table-column>
                <el-table-column prop="desc" label="描述"></el-table-column>
                <el-table-column label="操作" width="100px" fixed="right">
                    <template slot-scope="scope">
                        <el-dropdown trigger="click">
                            <el-button type="primary" plain size="mini">更多<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item @click.native="_edit(scope.row)">修改</el-dropdown-item>
                                <el-dropdown-item @click.native="deleteCategory(scope.row)">删除</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination-container" v-if="isPagination">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" 
                    :current-page="filter.page" :page-sizes="appPageSizes" :page-size="filter.size" 
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
        <el-dialog custom-class="c-el-dialog category-edit" :title="(editDialog.form.id?'修改':'添加')+'类别'" :visible.sync="editDialog.show">
            <el-form label-width="80px" :ref="refEdit" :rules="editDialog.rules" :model="editDialog.form">
                <el-form-item label="名称" prop="name">
                    <el-input v-model="editDialog.form.name"></el-input>
                </el-form-item>
                <el-form-item label="描述" prop="desc">
                    <el-input :rows="5" v-model="editDialog.form.desc" type="textarea"></el-input>
                </el-form-item>
                <el-form-item label="排序" prop="sort">
                    <el-input v-model="editDialog.form.sort"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="_close()">取 消</el-button>
                <el-button type="primary" @click="saveCategory">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import * as funCommon from "@/api/all"
export default {
    name: "pageMerchantCategorys",
    data() {
        return {
            refEdit: "refMerchantCategorysEdit",
            tableItems: {},
            tableData: [],
            total: null,
            isPagination: false,
            filter: {
                page: 1,
                size: window.$common.appPageSize,
                type: 2,
                keywords: ""
            },
            editDialog: {
                show: false,
                form: {
                    type: 2,
                    name: "",
                    desc: "",
                    sort: 0,
                    id: 0
                },
                rules: {
                    name: [{ required: true, message: "请输入名称", trigger: "blur" }],
                    sort: [{ required: true, message: "请输入排序", trigger: "blur" }]
                }
            }
        }
    },
    activated() {
        this.getDataList()
    },
    created() {
        this.getDataList()
    },
    methods: {
        handleSizeChange(val) {
            this.filter.size = val
            this.getDataList()
        },
        handleCurrentChange(val) {
            this.filter.page = val
            this.getDataList()
        },
        async getDataList() {
            window.$common.fullLoading()
            funCommon.CategoryList(this.filter).then(response => {
                window.$common.closeFullLoading()
                this.tableItems = response
                this.total = response.totalCount
                this.isPagination = response.totalCount > 0 || false
                if (response && response.items && response.items.length > 0) {
                    this.tableData = response.items
                } else {
                    this.tableData = []
                }
            })
        },
        _edit(row) {
            if (row && row.id) {
                this.editDialog.show = true
                this.editDialog.form.name = row.name
                this.editDialog.form.sort = row.sort
                this.editDialog.form.desc = row.desc
                this.editDialog.form.id = row.id
            } else {
                this.editDialog.show = true
                this.editDialog.form.name = ""
                this.editDialog.form.sort = ""
                this.editDialog.form.desc = ""
                this.editDialog.form.id = 0
            }
        },
        _close() {
            this.editDialog.show = false
            this.$nextTick(() => {
                if (this.$refs[this.refEdit]) {
                this.$refs[this.refEdit].resetFields()
                }
            })
        },
        saveCategory() {
            this.$refs[this.refEdit].validate(async (valid) => {
                if (valid) {
                    window.$common.fullLoading()
                    if (this.editDialog.form.id) {
                        funCommon.CategoryUpdate(this.editDialog.form).then(() => {
                            this.successMsg("修改成功", {
                                type: "success",
                                onClose: () => {
                                    this.getDataList()
                                    this.editDialog.show = false
                                }
                            })
                        })
                    } else {
                        funCommon.CategoryCreate(this.editDialog.form).then(() => {
                            this.successMsg("添加成功", {
                                type: "success",
                                onClose: () => {
                                    this.getDataList()
                                    this.editDialog.show = false
                                }
                            })
                        })
                    }
                }
            })
        },
        async deleteCategory(row) {
            this.confirm(`确认要删除该类别吗？`).then(() => {
                window.$common.fullLoading()
                funCommon.Categoryremove({ id: row.id }).then(() => {
                    this.successMsg("删除成功")
                    this.getDataList()
                })
            })
        },
        _search() {
            this.filter.page = 1
            this.getDataList()
        }
    }
}
</script>